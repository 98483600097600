<template>
	<div>
		<tap :taps="['发展历史']" :isMore="false"></tap>
		<div>
			<el-timeline>
				<el-timeline-item v-for="(item,index) in dongtai" :key="item.id" :timestamp="item.catname"
					placement="top">
					<el-card v-for="(item2,index2) in item.data" :key="index2" style="margin-top: 10px;">
						<h4 class="title">{{item2.title}}</h4>
						<p class="detail">{{item2.description}}</p>
					</el-card>
				</el-timeline-item>
			</el-timeline>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dongtai: []
			}
		},
		created() {
			this.getHisTory()
		},
		mounted() {},
		methods: {
			// 获取历程
			async getHisTory() {
				let url = this.$api.about.history
				let res = await this.$axios.post(url)
				if (res.code) {
					this.dongtai = res.data
				}
			}
		},
	}
</script>

<style lang="less" scoped>
	.title {
		font-size: 18px;
		font-weight: 600;
	}

	.detail {
		font-size: 14px;
		margin-top: 20px;
		line-height: 20px;
	}
	
	@media (max-width:768px) {
		/deep/ .el-timeline-item__wrapper {
			padding-left: 15px;
		}
	}
</style>
